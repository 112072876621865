
import { Link as Link_RRD } from "react-router-dom";

export default function Groups_Search_P()
{
  return (
    <div className="Groups_Search_P">


      <div className="Top-Holder">
        <h1>گروه ها</h1>
        
        <div className="Action-Buttons">
          <a href="#"><button>دعوتنامه ها</button></a>
          <Link_RRD to="/groups/create"><button>ایجاد گروه جدید</button></Link_RRD>
        </div>
      
      </div>
      <p className="back-to-home"><a href="/">بازگشت به صفحه اصلی</a></p>


      <p>هیچ گروهی برای نمایش وجود ندارد.</p>

    </div>
  );
}
