
import { useEffect, useState } from 'react';
import './NotFound.css';

export default function NotFound_P()
{
  const [ currentPath, set_currentPath ] = useState("");
  

  useEffect(() => {
    if (window.location.pathname != '/not-found')
    {
      localStorage.setItem("current-path", "Address: " + window.location.pathname);
      window.location.href = '/not-found'
    }
    else {
      let currentPath = localStorage.getItem('current-path');
      if (currentPath != null) {
        set_currentPath(currentPath)
      }
    }

    setTimeout(()=>{
      localStorage.setItem("current-path", "");
    }, 900);

  }, []);

  
  return (
    <div className="NotFound_P">
      <title>خطا: صفحه مورد نظر پیدا نشد!</title>

      <h1>خطا: صفحه مورد نظر پیدا نشد!</h1>

      <p>احتمال دارد یکی از اتفاقات زیر رخ داده باشد:</p>
      
      <ul>
        <li>آدرس به صورت صحیح و کامل وارد نشده است.</li>
        <li>شما دسترسی لازم برای مشاهده این صفحه را ندارید.</li>
        <li>صفحه مورد نظر حذف یا به آدرسی دیگر انتقال یافته است.</li>
      </ul>

      <p>اگر فکر می کنید این یک خطای نرم افزاریست، با پشتیبانی سامانه تماس بگیرید.</p>

      <div className='Action'>
        <a href='/'><button>بازگشت به صفحه اصلی</button></a>
      </div>

      <p className='current-path'>{currentPath}</p>

    </div>
  );
}
