
import './Panel.css';

import { 
  Switch as Switch_RRD,
  Route as Route_RRD  
} from "react-router-dom";

import Panel_Header_C from "./Components/Header";
import Panel_Sidebar_C from './Components/Sidebar';
import { useEffect } from 'react';
import NotFound_P from '../NotFound';
import Panel_Dashboard_P from './Sub/Dashboard';
import Panel_Account_Settings_P from './Sub/Account/Settings';
import Panel_Cases_Create_P from './Sub/Cases/Create';
import Panel_Cases_Search_P from './Sub/Cases/Search';
import Panel_Groups_Settings_P from './Sub/Groups/Settings';
import Panel_Invoices_Search_P from './Sub/Invoices/Search';
import Panel_Members_Search_P from './Sub/Members/Search';

export default function Panel_P()
{
  return (
    <div className="Panel_P">
      <Panel_Header_C />

      <div className="Container">
        <div className="Sidebar">
          <Panel_Sidebar_C />
        </div>


        <div className="Content">

          <div className='Loading hidden'>
            <p>درحال پردازش، لطفا صبر کنید ...</p>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>

          <div className='Loaded '>
            <Switch_RRD>
              
              <Route_RRD path="/" exact>
                <Panel_Dashboard_P />
              </Route_RRD>

              <Route_RRD path="/account/settings" exact>
                <Panel_Account_Settings_P />
              </Route_RRD>

              <Route_RRD path="/cases/create" exact>
                <Panel_Cases_Create_P />
              </Route_RRD>

              <Route_RRD path="/cases" exact>
                <Panel_Cases_Search_P />
              </Route_RRD>

              <Route_RRD path="/members" exact>
                <Panel_Members_Search_P />
              </Route_RRD>

              <Route_RRD path="/invoices" exact>
                <Panel_Invoices_Search_P />
              </Route_RRD>

              <Route_RRD path="/settings" exact>
                <Panel_Groups_Settings_P />
              </Route_RRD>

              <Route_RRD path="/*">
                <NotFound_P />
              </Route_RRD>

            </Switch_RRD>
          </div>

        </div>
      </div>
      
    </div>
  );
}
