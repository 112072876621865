
export default function Auth_PR_P()
{
  return (
    <div className="Auth_PR_P">
      <title>بازیابی رمز عبور | سامانه املاکی یار</title>
      <h1>بازیابی رمز عبور</h1>
      
      <div className="Form">
        <p className="error-message"><span>خطا: </span> شماره تلفن از قبل موجود است. </p>

        <p>{"۱."} شماره تلفن:</p>
        <input type="number" placeholder="مثال: 09123456789"/>
        <br />
        <br />

        <button>ارسال کد تایید</button>

      </div>
      
      <div className="Others">
        <p>- <a href="/auth/login">ورود به حساب کاربری</a> -</p>
        <p>- <a href="/auth/register">عضویت</a> -</p>
      </div>
    </div>
  );
}
