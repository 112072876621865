
import './Header.css'

import { Link as Link_RRD } from 'react-router-dom';

export default function Panel_Header_C()
{
  return (
    <div className="Panel_Header_C">
      <div className='Right'>
        <img src='/logo192.png' />
        <p>سامانه املاکی یار <small>(نسخه آنلاین)</small></p>
      </div>

      <div className='Left'>

        <Link_RRD className='Account_Settings' to='/account/settings'>تنظیمات حساب کاربری</Link_RRD>
        {/* <a href="#">خروج از حساب</a> */}
      </div>
    </div>
  );
}
