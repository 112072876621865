
export default function Auth_Register_Verify_P()
{
  return (
    <div className="Auth_Register_Verify_P">
      <title>تایید عضویت | سامانه املاکی یار</title>
      <h1>تایید عضویت</h1>
      
      <div className="Form">
        <p className="error-message"><span>خطا: </span> شماره تلفن از قبل موجود است. </p>

        <p>{"۱."} شماره تلفن: <span>۰۹۱۲۳۴۵۶۷۸۹</span></p>

        
        <p>{"۲."} کد تایید (ارسال شده به شماره تلفن):</p>
        <input type="text" placeholder="مثال: ۱۲۳۴۵۶"/>
        {/* <ul>
          <li>به شماره تلفن شما ارسال شد.</li>
        </ul> */}
        <br />
        <br />

        <button>اعتبار سنجی</button>

      </div>
      
      <div className="Others">
        <p>- <a href="/auth/register">اصلاح شماره تلفن</a> -</p>
      </div>
    </div>
  );
}
