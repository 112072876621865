

export default function Panel_Dashboard_P() 
{
  return (
    <div>
      <h1>پیشخوان</h1>

    </div>
  );
}
