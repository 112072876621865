
import { Link as Link_RRD } from 'react-router-dom';

import './Sidebar.css';

export default function Panel_Sidebar_C()
{
  return (
    <div className="Panel_Sidebar_C">

      <div className='Top'>
        <label>تغییر گروه: <small>(در کل مرورگر تغییر میکند)</small> </label>
        <select name="groups" id="groups">
          <option value="0">املاک ایرانیان</option>
          <option value="1">املاک پارسیان</option>
          <option value="2">فضای شخصی</option>
        </select> 
        <br />
        
        <div className='Group_Actions'>
          <a className='button-normal' href="/groups">مدیریت گروه ها</a>
          <br />
          <a className='button-normal' href="/groups/create">ایجاد گروه جدید</a>
        </div>
      </div>

      <div className='List'>
        <Link_RRD to="/"><p className='button-normal'>۱. پیشخوان</p></Link_RRD>
        <Link_RRD to="/cases/create"><p className='button-normal'>۲. ایجاد فایل</p></Link_RRD>
        <Link_RRD to="/cases"><p className='button-normal'>۳. جستجو فایل</p></Link_RRD>
        <Link_RRD to="/members"><p className='button-normal'>۴. مدیریت اعضاء</p></Link_RRD>
        <Link_RRD to="/invoices"><p className='button-normal'>۵. صورتحساب ها</p></Link_RRD>
        <Link_RRD to="/settings"><p className='button-normal'>۶. تنظیمات گروه</p></Link_RRD>
      </div>

    </div>
  );
}
