
import './Settings.css'

export default function Panel_Account_Settings_P() 
{
  return (
    <div className="Panel_Account_Settings_P">

      <div className='top-holder'>
        <h1>تنظیمات حساب کاربری</h1>
        <button className='button-warning'>خروج از حساب</button>
      </div>

      <p className='error-message'><span>خطا:</span> این یک پیام خطا می باشد.</p>

      <h3>مشخصات عمومی</h3>

      <p>شماره تلفن: <small>(برای تغییر شماره تلفن با پشتیبانی تماس بگیرید.)</small></p>
      <input value="09123456879" disabled />

      <p>نام و نام خانوادگی: <small>(به زبان فارسی و به صورت کامل وارد کنید.)</small></p>
      <input value="" />

      <br />
      <br />

      <button>ذخیره</button>

      <br />
      <br />

      <h3>تغییر رمز عبور</h3>

      <p className='to-change-password'>به منظور افزایش سطح امنیت حساب های کاربری مشترکان گرامی؛ جهت <b>تغییر رمز عبور حساب کاربری</b>، ابتدا از حساب کاربری خود خارج شده و سپس از صفحه ورود، با مراجعه به صفحه بازیابی رمز عبور می توانید برای تغییر رمز عبور حساب کاربری خود با امنیت و آرامش اقدام نمائید. </p>

    </div>
  );
}
