
export default function Auth_PR_Verify_P()
{
  return (
    <div className="Auth_PR_Verify_P">
      <title>تایید بازیابی رمز عبور | سامانه املاکی یار</title>
      <h1>تایید بازیابی رمز عبور</h1>
      
      <div className="Form">
        <p className="error-message"><span>خطا: </span> شماره تلفن از قبل موجود است. </p>

        <p>{"۱."} شماره تلفن: <span>۰۹۱۲۳۴۵۷۸۹</span></p>


        <p>{"۲."} کد تایید (ارسال شده به شماره تلفن):</p>
        <input type="number" placeholder="مثال: ۱۲۳۴۵۶"/>
        
        {/* <ul>
          <li>به شماره تلفن شما ارسال شد.</li>
        </ul> */}

        <br />
        
        <p>{"۳."} رمز عبور جدید:</p>
        <input type="password" placeholder="یک رمز مناسب انتخاب کنید"/>

        <ul>
          <li>حداقل ۸ حرف داشته باشد.</li>
          <li>بهتر است شامل اعداد و حروف باشد.</li>
        </ul>

        <br />

        <p>{"۴."} تکرار رمز عبور جدید:</p>
        <input type="password" placeholder="رمز انتخابی را دوباره وارد کنید" />
        <br />
        <br />

        <button>تغییر رمز عبور</button>

      </div>
      
      <div className="Others">
        <p>- <a href="/auth/login">انصراف</a> -</p>
      </div>
    </div>
  );
}
