
export default function Auth_Login_P()
{
  return (
    <div className="Auth_Login_P">
      <title>ورود | سامانه املاکی یار</title>
      <h1>ورود به املاکی یار</h1>
      
      <div className="Form">
        <p className="error-message"><span>خطا: </span> شماره تلفن از قبل موجود است. </p>

        <p>{"۱."} شماره تلفن:</p>
        <input type="number" placeholder="مثال: 09123456789"/>
        
        {/* <ul>
          <li>کد اعتبار سنجی ارسال می شود.</li>
        </ul> */}

        <br />
        
        <p>{"۲."} رمز عبور:</p>
        <input type="password" placeholder="رمز عبور خود را وارد کنید"/>
        <br />
        <br />

        <button>اعتبار سنجی</button>

      </div>
      
      <div className="Others">
        <p>- <a href="/auth/register">عضویت</a> -</p>
        <p>- <a href="/auth/pr">بازیابی رمز عبور</a> -</p>
      </div>
    </div>
  );
}
