
import './Auth.css';

import {
  Switch as Switch_RRD,
  Route as Route_RRD
} from "react-router-dom";

import Auth_Header_C from "./Components/Header";
import NotFound_P from "../NotFound";
import Auth_Register_P from './Sub/Register';
import Auth_Login_P from './Sub/Login';
import Auth_PR_P from './Sub/PR';
import Auth_Register_Verify_P from './Sub/Register_Verify';
import Auth_PR_Verify_P from './Sub/PR_Verify';
import { useEffect } from 'react';

export default function Auth_P()
{
  return (
    <div className="Auth">

      <Auth_Header_C />

      <div className="Content">
        <Switch_RRD>

          <Route_RRD path="/auth/register" exact>
            <Auth_Register_P />
          </Route_RRD>

          <Route_RRD path="/auth/register/verify" exact>
            <Auth_Register_Verify_P />
          </Route_RRD>

          <Route_RRD path="/auth/login" exact>
            <Auth_Login_P />
          </Route_RRD>

          <Route_RRD path="/auth/pr" exact>
            <Auth_PR_P />
          </Route_RRD>

          <Route_RRD path="/auth/pr/verify" exact>
            <Auth_PR_Verify_P />
          </Route_RRD>

          <Route_RRD path="/auth/*">
            <NotFound_P />
          </Route_RRD>

        </Switch_RRD>
      </div>

    </div>
  );
}
