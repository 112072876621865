
import './Groups.css';

import { 
  Switch as Switch_RRD,
  Route as Route_RRD  
} from "react-router-dom";

import NotFound_P from "../NotFound";
import Panel_Header_C from '../Panel/Components/Header';
import Groups_Search_P from './Sub/Search';
import Groups_Create_P from './Sub/Create';


export default function Groups_P() 
{
  return (
    <div className='Group_Search_P'>

      <Panel_Header_C />

      <div className="Container">

        <div className="Content">

          <div className='Loading hidden'>
            <p>درحال پردازش، لطفا صبر کنید ...</p>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>

          <div className='Loaded '>
            <Switch_RRD>
              
              <Route_RRD path="/groups/create" exact>
                <Groups_Create_P />
              </Route_RRD>


              <Route_RRD path="/groups" exact>
                <Groups_Search_P />
              </Route_RRD>


              <Route_RRD path="/*">
                <NotFound_P/>
              </Route_RRD>

            </Switch_RRD>
          </div>

        </div>
      </div>

    </div>
  );
}
