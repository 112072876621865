
export default function Auth_Register_P()
{
  return (
    <div className="Auth_Register_P">
      <title>عضویت | سامانه املاکی یار</title>
      <h1>عضویت در املاکی یار</h1>
      
      <div className="Form">
        <p className="error-message"><span>خطا: </span> شماره تلفن از قبل موجود است. </p>

        <p>{"۱."} نام و نام خانوادگی:</p>
        <input type="text" placeholder="مثال: محمد محمدی" />

        {/* <ul>
          <li>به زبان فارسی وارد کنید.</li>
        </ul> */}

        <br />

        <p>{"۲."} شماره تلفن:</p>
        <input type="number" placeholder="مثال: 09123456789"/>
        
        {/* <ul>
          <li>کد اعتبار سنجی ارسال می شود.</li>
        </ul> */}

        <br />
        
        <p>{"۳."} رمز عبور:</p>
        <input type="password" placeholder="یک رمز مناسب انتخاب کنید"/>

        <ul>
          <li>حداقل ۸ حرف داشته باشد.</li>
          <li>بهتر است شامل اعداد و حروف باشد.</li>
        </ul>

        <br />

        <p>{"۴."} تکرار رمز عبور:</p>
        <input type="password" placeholder="رمز انتخابی را دوباره وارد کنید" />
        <br />
        <br />

        <button>ارسال کد تایید</button>

      </div>
      
      <div className="Others">
        <p>- <a href="/auth/login">ورود به حساب کاربری</a> -</p>
      </div>
    </div>
  );
}
