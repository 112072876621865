
import './Header.css'

export default function Auth_Header_C()
{
  return (
    <div className="Auth_Header_C">
      <div className='Right'>
        <img src='/logo192.png' />
        <p>سامانه املاکی یار <small>(نسخه آنلاین)</small></p>
      </div>

      <div className='Left'>
        <a href='http://www.amlakiyar.ir'>بازگشت به وبسایت اصلی</a>
      </div>
    </div>
  );
}
