
import { Link as Link_RRD } from "react-router-dom";

export default function Groups_Create_P()
{
  return (
    <div>
      <div className="Top-Holder">
        <h1>ایجاد گروه جدید</h1>
        
        <div className="Action-Buttons">
          <Link_RRD to="/groups">بازگشت به گروه ها</Link_RRD>
        </div>
      
      </div>

    </div>
  );
}
