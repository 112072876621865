
import './Default.css';

import { 
  Switch as Switch_RRD,
  Route as Route_RRD  
} from "react-router-dom";

import Auth_P from "./Auth/Auth";
import NotFound_P from './NotFound';
import Panel_P from './Panel/Panel';
import Groups_P from './Groups/Groups';

function App() {
  return (
    <div className="App">
      <Switch_RRD>


        <Route_RRD path="/auth/*">
          <Auth_P />
        </Route_RRD>



        <Route_RRD path="/groups*" exact>
          <Groups_P />
        </Route_RRD>



        <Route_RRD path="/not-found" exact>
          <NotFound_P />
        </Route_RRD>


        <Route_RRD path="/*">
          <Panel_P />
        </Route_RRD>


      </Switch_RRD>



      <div className='Support_Div'>
        <p><a href="https://www.amlakiyar.ir/support" target='_blank'>پشتیبانی سامانه</a></p>
      </div>
    </div>
  );
}

export default App;
